<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a  class="navbar-toggle"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a   class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink="/admin/dashboard/dashboard-admin">
        <img width="100px" src="assets/images/logo.png" alt="" />
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <app-feather-icons [icon]="'menu'" [class]="'header-icon'"></app-feather-icons>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- <li class="nav-item" style="margin-right: 10px;">
          <div class="switch-toggle"  [ngClass]="isDarkSidebar? 'dark' : 'light'">
            <span class="light-span" (click)="darkThemeBtnClick()" >
              Light
            </span>
            <span class="dark-span" (click)="lightThemeBtnClick()">
              Dark
            </span>
            <div class="switch" [ngClass]="isDarkSidebar? 'dark' : 'light'">
              
            </div>
          </div>
        </li> -->
        <li class="nav-item user_profile btnUserDropdown" ngbDropdown>
          <div class="chip dropdown-toggle" ngbDropdownToggle class="nav-notification-icons pt-0">
            <img src="assets/images/user.png" class="user_img" width="32" height="32" alt="User">
            <span>{{user?.firstname}} {{user?.lastname}}</span>
          </div> 
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a [routerLink]="['/admin/dashboard/my-profile']" >
                        <app-feather-icons [icon]="'user'"   [class]="'user-menu-icons'"></app-feather-icons>Minha Conta
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>