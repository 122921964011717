import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertService } from '../_service/alert/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router,private alert: AlertService, private modal: NgbModal) { }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        console.log(err);
        if (err.status === 401) {
            localStorage.clear();
            this.router.navigate(['/']);
            this.modal.dismissAll();
            this.alert.error(err.error.message)
            
            return of(err.message);
            
        }

        return throwError(err);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');
        if (token != null) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request).pipe(catchError(err => this.handleAuthError(err)));
    }
}