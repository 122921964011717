import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor() { }

  warning(message: string): void {
    Swal.fire({
      icon: 'warning',
      text: message,
      showConfirmButton: false,
      timer: 1500
    });
  }

  success(message: string): void {
    Swal.fire({
      icon: 'success',
      text: message,
      showConfirmButton: false,
      timer: 1500
    });
  }


  show(message: string): void {
    Swal.fire({
      title: "Aguarde",
      text: message,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
  }

  hide(): void {
    Swal.close();
  }
}