import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { apiURL } from '../../_helper/url';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiURL: any = apiURL.url; //URL DA API

  constructor(private http:HttpClient) { }

  //---------------------------------------------------------------
  //Logar Usuario
  login(email: string, password: string) {
    return this.http.post<any>(`${this.apiURL}/auth/login`, { email, password })
      .pipe(map(data => {
        if (data.data && data.token) {
          localStorage.setItem('user', JSON.stringify(data.data));
          localStorage.setItem('token', data.token);
        }
        return data;
      }));
  }//fim login

  //---------------------------------------------------------------
  //Registrar Usuario
	register(name: string,password: string,email: string) {
		return this.http.post<any>(`${this.apiURL}/admin/register`, { name: name,password: password,email : email })
	}//fim register


  //---------------------------------------------------------------
  //Obter Usuario
  getUser(){
    return this.http.get<any>(`${this.apiURL}/users`)
  }

  //---------------------------------------------------------------
  //Esqueci minha senha
  forgotPassword(email: any) {
    return this.http.post<any>(`${this.apiURL}/users/forgotPassword`, { email: email})
  }

  //---------------------------------------------------------------
  //Alterar Senha Perdida
  changelostPassword(password_code: any,password:any) {
    return this.http.post<any>(`${this.apiURL}/users/changeLostPassword`, { password_code: password_code,password: password})
  }

  //---------------------------------------------------------------
  //Alterar Senha
  changePassword(form:FormGroup) {
    return this.http.post<any>(`${this.apiURL}/users/changePassword`,form.value)
  }

  //---------------------------------------------------------------
  //Alterar Dados pessoais do usuario
  update(form:FormGroup) {
    return this.http.patch<any>(`${this.apiURL}/users`,form.value)
  }

  //---------------------------------------------------------------
}
