<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu">
      <ul class="list"
        [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
        [perfectScrollbar]>
        <li class="sidebar-user-panel">
          <div class="user-panel">
            <div class=" image">
              <img src="assets/images/user.png" class="img-circle user-img-circle" alt="User Image" />
            </div>
          </div>
          <div class="profile-usertitle">
            <div class="sidebar-userpic-name">{{user?.firstname}} {{user?.lastname}} </div>
          </div>
        </li>
        <!-- Top Most level menu -->
        <div *ngIf="user?.role == 'ADMIN'">
          <li>
            <a class="" routerLink="/admin/dashboard/dashboard-admin" href="javascript:void(0)" class="menu-top">
              <i-feather name="home" class="sidebarIcon"></i-feather>
              <span class="hide-menu">Dashboard
              </span>
            </a>
          </li>
        </div>
        <div *ngIf="user?.role == 'ADMIN'">
          <li>
            <a class="" routerLink="/admin/dashboard/users" href="javascript:void(0)" class="menu-top">
              <i-feather name="users" class="sidebarIcon"></i-feather>
              <span class="hide-menu">Usuários
              </span>
            </a>
          </li>
        </div>
        <div *ngIf="user?.role == 'ADMIN'">
          <li>
            <a class="" routerLink="/admin/dashboard/clients" href="javascript:void(0)" class="menu-top">
              <i-feather name="users" class="sidebarIcon"></i-feather>
              <span class="hide-menu">Clientes
              </span>
            </a>
          </li>
        </div>
        <div *ngIf="user?.role == 'ADMIN'">
          <li>
            <a class="" routerLink="/admin/dashboard/groups" href="javascript:void(0)" class="menu-top">
              <i-feather name="align-justify" class="sidebarIcon"></i-feather>
              <span class="hide-menu">Grupos
              </span>
            </a>
          </li>
        </div>
        <div *ngIf="user?.role == 'ADMIN'">
          <li>
            <a class="" routerLink="/admin/dashboard/equipments" href="javascript:void(0)" class="menu-top">
              <i-feather name="file-plus" class="sidebarIcon"></i-feather>
              <span class="hide-menu">Equipamentos
              </span>
            </a>
          </li>
        </div>
        <div *ngIf="user?.role == 'ADMIN'">
          <li>
            <a class="" routerLink="/admin/dashboard/payment-conditions" href="javascript:void(0)" class="menu-top">
              <i-feather name="file-plus" class="sidebarIcon"></i-feather>
              <span class="hide-menu">Condição de Pagamento
              </span>
            </a>
          </li>
        </div>
        <div *ngIf="user?.role == 'ADMIN'">
          <li>
            <a class="" routerLink="/admin/dashboard/config-proposal" href="javascript:void(0)" class="menu-top">
              <i-feather name="file-plus" class="sidebarIcon"></i-feather>
              <span class="hide-menu">Configuração de Proposta
              </span>
            </a>
          </li>
        </div>
        <div *ngIf="user?.role == 'ADMIN'">
          <li>
            <a class="menu-top" (click)="clickFile()">
              <i-feather name="file-text" class="sidebarIcon menu-toggle" ></i-feather>
              <span class="hide-menu">Obrigações
              </span>
            </a>
            <!-- First level menu -->
            <ul class="fadein" *ngIf="showFiles">
              <li>
                <a class="" routerLink="/admin/dashboard/obligation-locator" href="javascript:void(0)" class="menu-top">
                  <span class="hide-menu">Locador
                  </span>
                </a>
              </li>
              <li>
                <a class="" routerLink="/admin/dashboard/obligation-tenant" href="javascript:void(0)" class="menu-top">
                  <span class="hide-menu">Locatário
                  </span>
                </a>
              </li>
              <li>
                <a class="" routerLink="/admin/dashboard/obligation-general-provision" href="javascript:void(0)" class="menu-top">
                  <span class="hide-menu">Gerais
                  </span>
                </a>
              </li>
            </ul>
            
          </li>
        </div>
        <div>
          <li>
            <a class="" routerLink="/admin/dashboard/lease-proposal" href="javascript:void(0)" class="menu-top">
              <i-feather name="file-plus" class="sidebarIcon"></i-feather>
              <span class="hide-menu">Proposta de Locação
              </span>
            </a>
          </li>
        </div>
        <li>
          <a class="" (click)="logout()" class="menu-top">
            <i-feather name="log-out" class="sidebarIcon"></i-feather>
            <span class="hide-menu">Logout
            </span>
          </a>
        </li>
      </ul>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>